import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const Admin_Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: './admin/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'manage/admins',
    loadChildren: './admin/manage-admin/manage-admin.module#ManageAdminModule'
  },
  {
    path: 'manage/customers',
    loadChildren: './admin/manage-customer/manage-customer.module#ManageCustomerModule'
  },
  {
    path: 'manage/reports',
    loadChildren: './admin/manage-report/manage-report.module#ManageReportModule'
  },
  {
    path: 'manage/rates',
    loadChildren: './admin/manage-calculation/manage-calculation.module#ManageCalculationModule'
  },
  {
    path: 'settings',
    loadChildren: './admin/setting/setting.module#SettingModule'
  }
];