import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    {
        path: '/admin/dashboard', title: 'Dashboard', icon: 'fa fa-tachometer', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/manage/admins', title: 'Manage Admins', icon: 'fa fa-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/manage/customers', title: 'Manage Customers', icon: 'fa fa-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/manage/rates', title: 'Manage Rates', icon: 'fa fa-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/manage/reports', title: 'Manage Reports', icon: 'fa fa-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/admin/settings', title: 'Settings', icon: 'fa fa-cog', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
