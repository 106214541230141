import { UserModel } from "./user.model";

export class ReportRateModel {

    constructor(public id: number = null,
        public calculation_id: number = null,
        public term: number = null,
        public report_rate: number = null
    ) {

    }
}