import { Component, OnInit } from '@angular/core';
import { PrimengTableService } from 'app/_services/primeng-table.service';

@Component({
  selector: 'app-common-sidebar',
  templateUrl: './common-sidebar.component.html',
  styleUrls: ['./common-sidebar.component.scss']
})
export class CommonSidebarComponent implements OnInit {
  public primengTableService: PrimengTableService;

  constructor() { }

  ngOnInit() {
  }

}
