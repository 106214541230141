import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'content-layout',
        loadChildren: './pages/content-layout-page/content-pages.module#ContentPagesModule'
    },
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule'
    },
    {
        path: 'admin',
        loadChildren: './admin/manage-admin/manage-admin.module#ManageAdminModule'
    },
    {
        path: 'customer',
        loadChildren: './admin/manage-customer/manage-customer.module#ManageCustomerModule'
    },
    {
    path: 'report',
    loadChildren: './customer/download-report/download-report.module#DownloadReportModule'
  }
];