import { ReportComponent } from './report/report.component';
import { AdminUserGuard } from './_guards/admin-user.guard';
import { CustomerUserGuard } from './_guards/customer-user.guard';
import { Admin_Full_ROUTES } from './shared/routes/admin-full-layout.routes';
import { Customer_Full_ROUTES } from './shared/routes/customer-full-layout.routes';
import { AuthGuard } from './_guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { Full_ROUTES } from "./shared/routes/full-layout.routes";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'public',
    component: ReportComponent
  },
  { path: 'customer', component: FullLayoutComponent, data: { title: '' }, children: Customer_Full_ROUTES, canActivate: [AuthGuard, CustomerUserGuard] },
  { path: 'admin', component: FullLayoutComponent, data: { title: '' }, children: Admin_Full_ROUTES, canActivate: [AuthGuard, AdminUserGuard] },
  { path: '', component: FullLayoutComponent, data: { title: '' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: '' }, children: CONTENT_ROUTES, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
