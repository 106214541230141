import { environment } from './../../../environments/environment';
import { AttachmentModel } from './../../_models/attachment.model';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  public attachments: AttachmentModel[];
  @Output() filesAdded = new EventEmitter<any>();
  @Output() filesRemoved = new EventEmitter<any>();

  @Input('existingAttachments')
  set existingAttachments(value: AttachmentModel[]) {
    this.attachments = value;
  }

  get existingAttachments() {
    return this.attachments;
  }

  public url: string;

  constructor() {
    this.attachments = new Array();
    this.url = environment.attachmentUploadUrl;
  }

  ngOnInit() {
  }

  beforeRequest(xhr) {
    xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('token'));
  }

  fileUploaded(event) {
    const attachment = <AttachmentModel>JSON.parse(event[1]);

    if (this.attachments === undefined) {
      this.attachments = new Array();
    }

    this.attachments.push(attachment);

    this.filesAdded.emit(attachment);
  }

  fileRemoved(attachment: AttachmentModel) {
    const index = this.attachments.findIndex(item => item.id === attachment.id);

    if (index !== -1) {
      this.attachments.splice(index, 1);
      this.filesRemoved.emit(attachment);
    }
  }

  beforeFileUpload(formData) {
    return formData;
  }

  filesUpdated(files) {

  }

  getTemplate() {
    return '<div class="xt-upload-container">\n' +
      '                <div class="xt-upload-logo-icon">\n' +
      '                   <div class="btn btn-primary btn-raised btn-upload btn-sm">\n' +
      '                       <i class="ft-upload mr-1"></i>Upload\n' +
      '                   </div>\n' +
      '               </div>\n' +
      '            </div>';
  }
}
