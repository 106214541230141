import { DomService } from './../_services/dom.service';
import { SafeHtmlPipe } from './../_pipes/safe-html.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from '@ng-select/ng-select';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvalidTooltipDirective } from 'app/_directives/invalid-tooltip.directive';
import { TableModule } from 'primeng/table';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileDroppa } from 'file-droppa';
import { CommonSidebarComponent } from './common-sidebar/common-sidebar.component'
@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        InvalidTooltipDirective,
        TableModule,
        FileUploadComponent,
        FileDroppa,
        SafeHtmlPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        FormsModule,
        TableModule,
        FileDroppa
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        InvalidTooltipDirective,
        FileUploadComponent,
        SafeHtmlPipe,
        CommonSidebarComponent
    ],
    providers: [DomService],
    entryComponents: [CommonSidebarComponent]
})
export class SharedModule { }
