import { Router } from '@angular/router';
import { AuthService } from './../../_services/auth.service';
import { Component, AfterViewChecked } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked {

    toggleClass = 'ft-maximize';
    placement = 'bottom-right'
    public isCollapsed = true;

    constructor(public authService: AuthService,
        public router: Router) {

    }

    ngAfterViewChecked() {
        setTimeout(() => {
            this.placement = 'bottom-right';
        }, 3000);
    }

    ToggleClass() {
        if (this.toggleClass === 'ft-maximize') {
            this.toggleClass = 'ft-minimize';
        }
        else
            this.toggleClass = 'ft-maximize'
    }

    logoutClicked() {
        this.authService.logout();
        this.router.navigate(['']);
    }
    
    editProfileClicked() {
        this.router.navigate(['edit-profile']);
    }
}
