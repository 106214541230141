import { Component } from '@angular/core';


import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(private router: Router,
        private titleService: Title) {

    }

    ngOnInit() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd)
            ).subscribe((event) => {
                const title = this.getTitle(this.router.routerState, this.router.routerState.root).join(' | ');
                this.titleService.setTitle(title);
            }
            );

    }

    getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
}