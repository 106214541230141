import { Observable } from 'rxjs';
import { CalculationModel } from './../_models/calculation.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalculationDetailModel } from 'app/_models/calculation-detail.model';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor(public http: HttpClient) { }

  getAllRecords() {
    return this.http.get('calculations')
      .map(
        (response: any) => {
          return <CalculationModel[]>response;
        }
      );
  }

  add(form: any) {
    return this.http.post('calculations', form);
  }

  update(form: any, id: number) {
    return this.http.post('calculations/' + id, form);
  }

  delete(id: number) {
    return this.http.post('calculations/' + id, {
      _method: "DELETE"
    });
  }

  get(id: number): Observable<CalculationModel> {
    return this.http.get('calculations/' + id)
      .map(
        (response: any) => {
          return <CalculationModel>response;
        }
      );
  }

  getDetails(id: number) {
    return this.http.get('calculations/custom/get/details/' + id)
      .map(
        (response: any) => {
          return <CalculationDetailModel[]>response;
        }
      );
  }

  getDetail(id: number): Observable<CalculationDetailModel> {
    return this.http.get('calculation_details/' + id)
      .map(
        (response: any) => {
          return <CalculationDetailModel>response;
        }
      );
  }
}
