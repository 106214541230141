import { CalculationService } from "./_services/calculation.service";
import { AuthService } from "./_services/auth.service";
import { AuthGuard } from "./_guards/auth.guard";
import { HttpConfigInterceptor } from "./_interceptors/httpconfig.interceptor";

import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { NgxCurrencyModule } from "ngx-currency";

import * as $ from "jquery";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { WINDOW_PROVIDERS } from "./_services/window.service";
import { ReportComponent } from "./report/report.component";
@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    ReportComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NgxCurrencyModule,
    AppRoutingModule,
    SharedModule,
    NgbModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    CalculationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
