import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { CalculationModel } from "./../_models/calculation.model";
import { forkJoin } from "rxjs/observable/forkJoin";
import { CalculationService } from "./../_services/calculation.service";
import { CustomValidators } from "ng2-validation";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportRateModel } from "app/_models/report-rate.model";
import { ReportModel } from "app/_models/report.model";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {
  public viewReport: boolean = false;
  public report: ReportModel;

  public loading: boolean = false;
  public form: FormGroup;
  public types: any[] = [
    { id: "Rate", label: "Standard" },
    { id: "Step Up", label: "Step-up (Two Strikes)" }
  ];
  public today: any;
  public calculations: CalculationModel[];

  constructor(
    public fb: FormBuilder,
    public http: HttpClient,
    public calculationService: CalculationService,
    public router: Router
  ) {
    this.today = new Date();

    forkJoin([this.calculationService.getAllRecords()]).subscribe(result => {
      this.calculations = [...result["0"]];
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [null, [Validators.required, CustomValidators.email]],
      effective_date: [null],
      loan_amount: [10000000, [Validators.required, CustomValidators.number]],
      cap_strike_rate_type: ["Rate", Validators.required],
      report_rates: this.fb.array([])
    });

    this.capTypeSelected();
  }

  addArrayForStepUp(reportRate: ReportRateModel) {
    const records = this.form.get("report_rates") as FormArray;
    records.push(
      this.fb.group({
        calculation: this.fb.group({
          id: [reportRate.calculation_id, [Validators.required]]
        }),
        term: [
          reportRate.term,
          [
            Validators.required,
            CustomValidators.number,
            CustomValidators.min(1)
          ]
        ]
      })
    );
  }

  deleteRates() {
    const formArray = this.form.get("report_rates") as FormArray;
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  capTypeSelected() {
    this.deleteRates();

    if (this.form.value.cap_strike_rate_type === "Step Up") {
      this.addArrayForStepUp(new ReportRateModel());
      this.addArrayForStepUp(new ReportRateModel());
    } else if (this.form.value.cap_strike_rate_type === "Rate") {
      this.addArrayForStepUp(new ReportRateModel());
    }
  }

  rateSelected(_index: number) {
    if (this.form.value.cap_strike_rate_type === "Step Up" && _index === 0) {
      const records = this.form.get("report_rates") as FormArray;
      const _secondRecord = records.at(1);
      const _firstRecord = records.at(0);

      if (_firstRecord.get("term").valid) {
        _secondRecord
          .get("term")
          .setValidators([
            Validators.required,
            // CustomValidators.min(_firstRecord.value.term),
            CustomValidators.max(120)
          ]);
      }
    }
  }

  onSubmit() {
    this.loading = true;

    this.http
      .post("view/report", this.form.value)
      .map((response: any) => {
        return <ReportModel>response;
      })
      .subscribe(result => {
        this.report = result;
        this.viewReport = true;
        this.loading = false;
      });
  }

  chaneAssumptionsClicked() {
    // this.report = result;
    this.viewReport = false;
    this.loading = false;
  }
  download() {
    // this.http.get('download/report/' + this.report.id, { responseType: 'blob' })
    //   .map(
    //     (response: any) => {
    //       var newBlob = new Blob([<any>response], { type: "application/pdf" });
    //       const blobUrl = URL.createObjectURL(newBlob);
    //       const url = window.URL.createObjectURL(newBlob);
    //       window.open(url);
    //     }
    //   )
    //   .subscribe(
    //     result => {

    //     }
    //   );
    this.loading = true;

    this.http.get("reports/custom/send/report/" + this.report.id).subscribe(
      result => {
        window.top.location.href =
          "https://derivativelogic.com/thank-you-rate-cap";
      },
      error => {
        this.loading = false;
      }
    );
  }

  checkValue() {
    let value = 0;
    const rates = this.form.get('report_rates')['controls'];

    for (const rate of rates) {
      if (rate.get('term').valid)
        value += parseInt(rate.value.term);
    }

    if (value > 120)
      return true;
    else
      return false;
  }
}
